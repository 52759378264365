import { Activity, CreditCard, DollarSign, HeartHandshakeIcon, Users, SendIcon } from "lucide-react"

import { Button } from "../component/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../component/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../component/ui/tabs"
import { CalendarDateRangePicker } from "../component/date-range-picker"
import { MainNav } from "../component/main-nav"
import { Overview } from "../component/overview"
import { RecentSales } from "../component/recent-sales"
import { Search } from "../component/search"
import { UserNav } from "../component/user-nav"
import { addDays, format, set } from "date-fns"
import React, { useEffect } from "react"
import { DateRange } from "react-day-picker"
import { useDataSource } from "firecms"
import { collection, getCountFromServer, getFirestore, onSnapshot, query, where } from "firebase/firestore"

export default function DashboardPage() {
    const db = getFirestore()
    const [date, setDate] = React.useState<DateRange | undefined>({
        // one week ago
        from: new Date(),
        to: new Date(),
    })

    const [data, setData] = React.useState<any>({
        promotieRequest: 0,
        agendaEntry: 0,
        spreekEenJongerenwerker: 0,
        organiseer: 0,
        activeNow: 0,
        overzicht: [
            { "name": "Jan", "total": 0 },
            { "name": "Feb", "total": 0 },
            { "name": "Maa", "total": 0 },
            { "name": "Apr", "total": 0 },
            { "name": "Mei", "total": 0 },
            { "name": "Jun", "total": 0 },
            { "name": "Jul", "total": 0 },
            { "name": "Aug", "total": 0 },
            { "name": "Sep", "total": 0 },
            { "name": "Okt", "total": 0 },
            { "name": "Nov", "total": 0 },
            { "name": "Dec", "total": 0 },
        ],
        lastPeriod: {
            promotieRequest: 0,
            organiseer: 0,
            agendaEntry: 0,
            spreekEenJongerenwerker: 0,
            activeNow: 0,
        }
    })

    useEffect(() => {
        const getStats = async () => {
            console.log("stats", date)

            // Get Overzicht Count from January to December from this year
            const overzichtQueryJan = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 0, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 0, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryFeb = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 1, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 1, 28), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryMar = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 2, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 2, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryApr = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 3, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 3, 30), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryMay = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 4, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 4, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryJun = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 5, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 5, 30), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryJul = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 6, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 6, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryAug = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 7, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 7, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQuerySep = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 8, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 8, 30), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryOct = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 9, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 9, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryNov = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 10, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 10, 30), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))
            const overzichtQueryDec = query(collection(db, "Problem-Request"),
                where("createdAt", ">=", set(new Date(new Date().getFullYear(), 11, 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(new Date(new Date().getFullYear(), 11, 31), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            const helpQuery = query(collection(db, "AgendaEntry"),
                where("status", "==", "0"),
                where("createdAt", ">=", set(date?.from || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(date?.to || new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            // check date range and check for example if its a week ago or a month ago
            const lastWeekHelpQuery = query(collection(db, "AgendaEntry"),
                where("createdAt", ">=", set(addDays(date?.from || new Date(), -7), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(addDays(date?.to || new Date(), -7), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            const letsTalksQuery = query(collection(db, "Organiseer"),
                where("createdAt", ">=", set(date?.from || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(date?.to || new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            const lastWeekLetsTalksQuery = query(collection(db, "Organiseer"),
                where("createdAt", ">=", set(addDays(date?.from || new Date(), -7), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(addDays(date?.to || new Date(), -7), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            const organizeQuery = query(collection(db, "Promotie"),
                where("createdAt", ">=", set(date?.from || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(date?.to || new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })),
                where("status", "==", 0))

            const lastWeekOrganizeQuery = query(collection(db, "Promotie"),
                where("createdAt", ">=", set(addDays(date?.from || new Date(), -7), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
                where("createdAt", "<=", set(addDays(date?.to || new Date(), -7), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })),
                where("status", "==", 0))

            // const talkQuery = query(collection(db, "LetsTalk"),
            //     where("createdAt", ">=", set(date?.from || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
            //     where("createdAt", "<=", set(date?.to || new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            // const ProblemRequest = query(collection(db, "Problem-Request"),
            //     where("createdAt", ">=", set(date?.from || new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })),
            //     where("createdAt", "<=", set(date?.to || new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })))

            const helpSnapshot = await getCountFromServer(helpQuery)
            const lastWeekHelpSnapshot = await getCountFromServer(lastWeekHelpQuery)
            const letsTalksSnapshot = await getCountFromServer(letsTalksQuery)
            const lastWeekLetsTalksSnapshot = await getCountFromServer(lastWeekLetsTalksQuery)
            const organizeSnapshot = await getCountFromServer(organizeQuery)
            const lastWeekOrganizeSnapshot = await getCountFromServer(lastWeekOrganizeQuery)
            const overzicht = [
                { "name": "Jan", "total": (await getCountFromServer(overzichtQueryJan)).data().count },
                { "name": "Feb", "total": (await getCountFromServer(overzichtQueryFeb)).data().count },
                { "name": "Maa", "total": (await getCountFromServer(overzichtQueryMar)).data().count },
                { "name": "Apr", "total": (await getCountFromServer(overzichtQueryApr)).data().count },
                { "name": "Mei", "total": (await getCountFromServer(overzichtQueryMay)).data().count },
                { "name": "Jun", "total": (await getCountFromServer(overzichtQueryJun)).data().count },
                { "name": "Jul", "total": (await getCountFromServer(overzichtQueryJul)).data().count },
                { "name": "Aug", "total": (await getCountFromServer(overzichtQueryAug)).data().count },
                { "name": "Sep", "total": (await getCountFromServer(overzichtQuerySep)).data().count },
                { "name": "Okt", "total": (await getCountFromServer(overzichtQueryOct)).data().count },
                { "name": "Nov", "total": (await getCountFromServer(overzichtQueryNov)).data().count },
                { "name": "Dec", "total": (await getCountFromServer(overzichtQueryDec)).data().count },
            ]

            setData({
                ...data,
                agendaEntry: helpSnapshot.data().count,
                organiseer: letsTalksSnapshot.data().count,
                promotieRequest: organizeSnapshot.data().count,
                overzicht: overzicht,
                lastPeriod: {
                    ...data.lastPeriod,
                    agendaEntry: lastWeekHelpSnapshot.data().count,
                    organiseer: lastWeekLetsTalksSnapshot.data().count,
                    promotieRequest: lastWeekOrganizeSnapshot.data().count,
                }
            })
        }

        getStats()

    }, [date])

    const onChange = (date: DateRange) => {
        setDate(date)
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         if (!date?.to || !date?.from) return
    //         const agenda = collection(db, "Agenda");
    //         const help = collection(db, "Help");
    //         const talk = collection(db, "LetsTalk");
    //         const probReq = collection(db, "Problem-Request");

    //         // get collection once and count
    //         const qAgenda = query(agenda,
    //             // where("date", ">=", date.from), where("date", "<=", date.to)
    //         );
    //         const qHelp = query(help,
    //             // where("date", ">=", date.from), where("date", "<=", date.to)
    //         );
    //         const qTalk = query(talk,
    //             // where("date", ">=", date.from), where("date", "<=", date.to)
    //         );
    //         const qProbReq = query(probReq,
    //             // where("date", ">=", date.from), where("date", "<=", date.to)
    //         );

    //         const sAgenda = await getCountFromServer(qAgenda);
    //         const sHelp = await getCountFromServer(qHelp);
    //         const sTalk = await getCountFromServer(qTalk);
    //         const sProbReq = await getCountFromServer(qProbReq);
    //         setData({
    //             ...data,
    //             organize: sAgenda.data().count,
    //             help: sHelp.data().count,
    //             talk: sTalk.data().count,
    //             activeNow: sProbReq.data().count,
    //         })
    //     }
    //     fetchData()

    // }, [date])

    const calculatePercentage = (a: number, b: number) => {
        return ((b - a) / b) * 100
    }



    return (
        <div className="flex-col md:flex">
            <div className="flex-1 space-y-4 p-8 pt-6">
                <div className="flex items-center justify-between space-y-2 flex-col sm:flex-row">
                    <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
                    <div className="flex items-center space-x-2">
                        <CalendarDateRangePicker handleDateChange={onChange} />
                    </div>
                </div>

                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                    <Card className="hover:scale-[1.02] cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => window.location.href = "/c/agenda"}>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Organiseer aanmeldingen
                            </CardTitle>
                            <SendIcon className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{data.promotieRequest}</div>
                            <p className="text-xs text-muted-foreground">
                                {/* check percentage increase or decrease. calculate percentage increase between two numbers */}
                                {data.lastPeriod.promotieRequest > data.promotieRequest ? "-" : "+"}{data.lastPeriod.promotieRequest === 0 ? "0" : calculatePercentage(data.lastPeriod.promotieRequest, data.promotieRequest)}%  from last week
                            </p>
                        </CardContent>
                    </Card>
                    <Card className="hover:scale-[1.02] cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => window.location.href = "/c/LetsTalk-Request"}>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Agenda aanmeldingen
                            </CardTitle>
                            <Users className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{data.agendaEntry}</div>
                            <p className="text-xs text-muted-foreground">
                                {/* check percentage increase or decrease. calculate percentage increase between two numbers */}
                                {data.lastPeriod.agendaEntry > data.agendaEntry ? "-" : "+"}{data.lastPeriod.agendaEntry === 0 ? "0" : calculatePercentage(data.lastPeriod.agendaEntry, data.agendaEntry)}%  from last week
                            </p>
                        </CardContent>
                    </Card>
                    <Card className="hover:scale-[1.02] cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => window.location.href = "/c/Problem-Request"}>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Organiseer</CardTitle>
                            <HeartHandshakeIcon className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{data.organiseer}</div>
                            {!isNaN(calculatePercentage(data.lastPeriod.organiseer, data.organiseer)) ? <p className="text-xs text-muted-foreground">
                                {/* check percentage increase or decrease. calculate percentage increase between two numbers */}
                                {data.lastPeriod.organiseer > data.organiseer ? "-" : "+"}{data.lastPeriod.organiseer === 0 ? "0" : calculatePercentage(data.lastPeriod.organiseer, data.organiseer)}%  from last week
                            </p> : null}
                        </CardContent>
                    </Card>
                    {/* <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Nu actief
                            </CardTitle>
                            <Activity className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{data.activeNow}</div>
                        </CardContent>
                    </Card> */}
                </div>
                <div className="grid gap-y-4 sm:gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-7">
                    <Card className="col-span-4">
                        <CardHeader>
                            <CardTitle>Overzicht (Verzoek om Organiseer)</CardTitle>
                        </CardHeader>
                        <CardContent className="pl-2">
                            <Overview data={data.overzicht} />
                        </CardContent>
                    </Card>
                    <Card className="col-span-3 hover:scale-[1.02] cursor-pointer transition-transform duration-300 ease-in-out" onClick={() => window.location.href = "/c/Problem-Request"}>
                        <CardHeader>
                            <CardTitle>Recente aanmeldingen (Verzoek om Spreek een jongerenwerker)</CardTitle>
                            {/* <CardDescription>
                                Er zijn {data.promotieRequest} aanmeldingen tussen {format(date?.from || new Date(), "dd MMMM yyyy")} en {format(date?.to || new Date(), "dd MMMM yyyy")}
                            </CardDescription> */}
                        </CardHeader>
                        <CardContent>
                            <RecentSales date={date} />
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}