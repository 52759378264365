import { useMemo } from "react";

import { GoogleAuthProvider } from "firebase/auth";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import logo from "./images/logo.svg";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";

import {
  buildCollection,
  buildEntityCallbacks,
  CircularProgressCenter,
  CMSView,
  createCMSDefaultTheme,
  EntityOnDeleteProps,
  EntityOnSaveProps,
  FirebaseAuthController,
  FirebaseLoginView,
  FireCMS,
  ModeControllerProvider,
  NavigationRoutes,
  PropertyPreviewProps,
  SideDialogs,
  SnackbarProvider,
  useBuildModeController,
  useFirebaseAuthController,
  useFirebaseStorageSource,
  useFirestoreDataSource,
  useInitialiseFirebase,
  useValidateAuthenticator,
} from "firecms";

import { firebaseConfig } from "../firebase_config";
import { Scaffold } from "../../../lib/src";
import { orange } from "@mui/material/colors";
import DashboardPage from "../dashboard/DashboardApp";
import { HeartHandshakeIcon } from "lucide-react";

const DEFAULT_SIGN_IN_OPTIONS = ["password"];

function DateStringPreview({
  value,
  property,
  size,
}: PropertyPreviewProps<string>) {
  return <span>{new Date(value).toLocaleDateString()}</span>;
}

const timeEnums = Array.from({ length: 96 }, (_, i) => {
  const hours = Math.floor(i / 4);
  const minutes = (i % 4) * 15;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
});

type Agenda = {
  dateOfActivity: Date;
  day: number;
  month: number;
  year: number;
  eventTimeStart: string;
  eventTimeEnd: string;
};

const agendaCallback = buildEntityCallbacks({
  onPreSave: ({
    collection,
    path,
    entityId,
    values,
    previousValues,
    status,
  }) => {
    // update date var, use day, month, year and eventTimeStart
    const date = new Date(
      values.year,
      values.month,
      values.day,
      parseInt(values.eventTimeStart.split(":")[0]),
      parseInt(values.eventTimeStart.split(":")[1])
    );
    values.dateOfActivity = date;
    return values;
  },
});

const agendaCollection = buildCollection({
  callbacks: agendaCallback,
  icon: "CalendarIcon",
  path: "Agenda",
  group: "Main",
  permissions: ({ user }) => ({
    edit:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
    create: true,
    delete:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
  }),
  name: "Agenda",
  properties: {
    flyerFront: {
      name: "Voorafbeelding van de flyer",
      description:
        "Deze afbeelding wordt gebruikt als voorafbeelding van de flyer",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "agenda",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000",
        },
        fileName: (context) => {
          return context.file.name;
        },
      },
    },
    flyerBack: {
      name: "Achterafbeelding van de flyer",
      description:
        "Deze afbeelding wordt gebruikt als achterafbeelding van de flyer",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "agenda",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000",
        },
        fileName: (context) => {
          return context.file.name;
        },
      },
    },
    nameOfActivity: {
      name: "Naam van de activiteit",
      description:
        "Deze naam laat de gebruiker weten waar de activiteit over gaat",
      validation: { required: true },
      dataType: "string",
    },
    longDescription: {
      name: "Beschrijving",
      validation: { required: true },
      dataType: "string",
    },
    nameOfTheOrganizer: {
      name: "Naam van de organisator",
      description:
        "Deze naam laat de gebruiker weten wie de activiteit organiseert",
      validation: { required: true },
      dataType: "string",
    },
    placeOfTheActivity: {
      name: "Plaats van de Activiteit",
      description:
        "Deze plaats laat de gebruiker weten waar de activiteit plaatsvindt",
      validation: { required: true },
      dataType: "string",
    },
    // status: {
    //   // TODO: Figure out for what this is
    //   name: "Status",
    //   validation: { required: true },
    //   dataType: "string",
    //   description: "Mocht dit product zichtbaar zijn op de website",
    //   longDescription:
    //     "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
    //   enumValues: {
    //     "2": {
    //       id: "2",
    //       label: "Resolved",
    //     },
    //     "1": {
    //       id: 1,
    //       label: "In Progress",
    //     },
    //     "0": {
    //       id: 0,
    //       label: "New",
    //     },
    //   },
    // },
    whoIsItOrganizedFor: {
      name: "Voor wie wordt het georganiseerd?",
      validation: { required: true },
      dataType: "string",
    },
    activityLink: {
      name: "Link naar de activiteit",
      validation: { required: true },
      dataType: "string",
      description:
        "Deze link wordt gebruikt om de gebruiker om te leiden naar de activiteitenpagina",
    },
    // dateOfActivity: {
    //   name: "Datum van de Activiteit",
    //   description:
    //     "Deze datum laat de gebruiker weten wanneer de activiteit plaatsvindt",
    //   validation: { required: true },
    //   dataType: "date",
    // },

    day: {
      name: "Dag",
      validation: { required: true },
      dataType: "number",
      // hideFromCollection: true,
    },
    month: {
      name: "Maand",
      validation: { required: true },
      dataType: "number",
      // hideFromCollection: true,
      // disabled: true,
    },
    year: {
      name: "Jaar",
      validation: { required: true },
      dataType: "number",
      defaultValue: new Date().getFullYear(),
      // hideFromCollection: true,

      // disabled: true,
    },
    eventTimeStart: {
      // TODO: Hide from Collection after Debugging
      name: "Activiteit Starttijd",
      validation: { required: true },
      dataType: "string",
      // disabled: true,
    },
    eventTimeEnd: {
      name: "Activiteit Eindtijd",
      validation: { required: true },
      dataType: "string",
      // disabled: true,
    },
  },
});

const helpCollection = buildCollection({
  icon: "HeartHandshake",
  path: "Help",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  name: "Help",
  properties: {
    title: {
      name: "Naam",
      description: "Dit is de naam van het help-item",
      validation: { required: true },
      dataType: "string",
    },
    smallImage: {
      name: "Kleine afbeelding",
      description: "Dit is de kleine afbeelding van het help-item",
      validation: { required: true },
      dataType: "string",
      storage: {
        storagePath: "help",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000",
        },
        fileName: (context) => {
          return context.file.name;
        },
      },
    },
    largeImage: {
      name: "Grote afbeelding",
      description: "Dit is de grote afbeelding van het help-item",
      validation: { required: true },
      dataType: "string",
      storage: {
        storagePath: "help",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000",
        },
        fileName: (context) => {
          return context.file.name;
        },
      },
    },

    longDescription: {
      name: "Lange beschrijving",
      longDescription:
        "Dit is de lange beschrijving van het help-item (raadpleeg de documentatie)",
      description:
        "Dit is de lange beschrijving van het help-item (raadpleeg de documentatie)",
      validation: { required: true },
      dataType: "string",
      columnWidth: 400,
    },
    shortDescription: {
      name: "Korte beschrijving",
      description:
        "Dit is de korte beschrijving van het help-item (raadpleeg de documentatie)",
      validation: { required: true },
      dataType: "string",
      columnWidth: 340,
    },
    category: {
      name: "Categorie",
      validation: { required: true },
      dataType: "string",
      description:
        "Deze categorie laat de gebruiker weten waar het help-item over gaat (raadpleeg de documentatie)",
      longDescription:
        "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      enumValues: {
        werk: {
          id: 2,
          label: "Werk",
        },
        social: {
          id: 1,
          label: "Social",
        },
        school: {
          id: 0,
          label: "Shool",
        },
      },
    },
  },
});

const organiseerCollection = buildCollection({
  path: "Organiseer",
  icon: "HeartHandshake",
  group: "Main",

  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
    read:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
  }),
  name: "Organiseer",
  properties: {
    name: {
      name: "Name",
      validation: { required: true },
      dataType: "string",
    },
    achternaam: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    wijlk: {
      name: "Wijk",
      validation: { required: true },
      enumValues: {
        "": "",
        Noord: "Noord",
        Oost: "Oost",
        West: "West",
        "Centrum/Binnenstad": "Centrum/Binnenstad",
        Stiphout: "Stiphout",
        Warande: "Warande",
        Dierdonk: "Dierdonk",
        Rijpelberg: "Rijpelberg",
        Brandevoort: "Brandevoort",
        "'t Hout": "'t Hout",
        Industriegebieden: "Industriegebieden",
        Brouwhuis: "Brouwhuis",
      },
      dataType: "string",
    },
    phoneNumber: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    // Voor wie is jouw activiteit bedoeld
    targetGroup: {
      name: "oor wie/wat wil jij iets doen?",
      validation: { required: true },
      enumValues: {
        jongeren: "Jongeren",
        ouderen: "Ouderen",
        "voor de buurt": "Voor de buurt",
        kinderen: "Kinderen",
        overige: "Overige",
      },
      dataType: "string",
    },
    targetActivity: {
      name: "Overige?",
      validation: { required: false },
      dataType: "string",
    },
  },
});

const spreekEenJongerenwerkerCollection = buildCollection({
  path: "SpreekEenJongerenwerker",
  icon: "MessagesSquare",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: false,
    delete: true,
    read:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
  }),
  name: "Spreek een jongerenwerker",
  properties: {
    name: {
      name: "Voornaam",
      validation: { required: true },
      dataType: "string",
    },
    achternaam: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    phoneNumber: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
  },
});

const problemRequest = buildCollection({
  icon: "AlertCircle",
  path: "Problem-Request",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  name: "Hulpverzoeken",
  properties: {
    dateOfBirth: {
      name: "Geboortedatum",
      validation: { required: true },
      dataType: "date",
    },
    email: {
      name: "E-mail",
      validation: { required: true },
      dataType: "string",
    },
    firstName: {
      name: "Voornaam",
      validation: { required: true },
      dataType: "string",
    },
    lastName: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    phone: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
    status: {
      name: "Toestand",
      validation: { required: true },
      dataType: "string",
      description: "Should this product be visible in the website",
      longDescription:
        "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      enumValues: {
        "2": {
          id: "2",
          label: "Resolved",
        },
        "1": {
          id: 1,
          label: "In Progress",
        },
        "0": {
          id: 0,
          label: "Awaiting Review",
        },
      },
    },
  },
});

const talkRequest = buildCollection({
  path: "Meedoen",
  icon: "MessagesSquare",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  name: "Laten we praten verzoek",
  properties: {
    firstName: {
      name: "Voornaam",
      validation: { required: true },
      dataType: "string",
    },
    lastName: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    phone: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
    wijlk: {
      name: "Wijk",
      validation: { required: true },
      enumValues: {
        "": "",
        Noord: "Noord",
        Oost: "Oost",
        West: "West",
        "Centrum/Binnenstad": "Centrum/Binnenstad",
        Stiphout: "Stiphout",
        Warande: "Warande",
        Dierdonk: "Dierdonk",
        Rijpelberg: "Rijpelberg",
        Brandevoort: "Brandevoort",
        "'t Hout": "'t Hout",
        Industriegebieden: "Industriegebieden",
        Brouwhuis: "Brouwhuis",
      },
      dataType: "string",
    },
  },
});

const usersCollection = buildCollection({
  icon: "Users",
  path: "users",
  group: "Main",
  singularName: "Medewerker",
  description: "Geef werknemers toestemming",

  // icon: "Person",
  permissions: ({ user, authController }) => {
    console.log("authController", authController.extra?.roles);
    const writeRoles = ["full", "users"];
    const writeAccess = authController.extra?.roles.some((role: string) =>
      writeRoles.includes(role)
    );
    return {
      edit: writeAccess,
      create: true,
      delete: writeAccess,
      read:
        user?.email === "aanmeldingen@jonginhelmond.nl" ||
        user?.email === "tajjioumohamed97@gmail.com",
    };
  },
  name: "Medewerkers",
  properties: {
    firstName: {
      name: "First Name",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    lastLoginAt: {
      name: "Last Login At",
      validation: { required: false },

      dataType: "date",
      mode: "date_time",
      readOnly: true,
    },
    // Firebase Functions Listen to Accepted Invites and change the status from pending to active
    status: {
      name: "Status",
      defaultValue: "pending",
      disabled: true,
      dataType: "string",
      enumValues: {
        active: "Active",
        pending: "Pending",
      },
    },
    permissions: {
      name: "Permissions",
      validation: { required: true },
      disabled: true,
      dataType: "array",
      of: {
        dataType: "string",
        previewAsTag: true,
        description: "test",
        enumValues: {
          full: "volledige toegang",
          agenda: "Toegangsagenda lezen/schrijven",
          // help: "Help-items lezen/schrijven",
          // "help-request": "Hulpverzoeken voor lees-/schrijftoegang",
          // users: "Gebruikers met lees-/schrijftoegang",
          // "letstalk-request": "Lees-/schrijftoegang Laten we praten Verzoek",
          // "problem-request": "Lees-/schrijftoegang Problemen Verzoek",
        },
      },
    },
    createdAt: {
      name: "Created At",
      validation: { required: true },
      dataType: "date",
      mode: "date_time",
      autoValue: "on_create",
    },
    updatedAt: {
      name: "Updated At",
      validation: { required: true },
      dataType: "date",
      mode: "date_time",
      autoValue: "on_update",
    },
  },
});

// Name and Description should be in Dutch
const promotionCollection = buildCollection({
  path: "Promotie",
  icon: "Gift",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
    read:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
  }),
  name: "Promotie",
  properties: {
    sendForm: {
      name: "Formulier verzenden",
      validation: { required: true },
      dataType: "boolean",
      columnWidth: 250,
    },
    customerType: {
      name: "Customer Type",
      validation: { required: true },
      enumValues: {
        organisatie: "Organisatie",
        particulier: "Particulier",
      },
      dataType: "string",
    },
    name: {
      name: "Name",
      validation: { required: true },
      dataType: "string",
    },
    achternaam: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    organisatie: {
      name: "Organisatie",
      validation: { required: false },
      dataType: "string",
    },
    wijlk: {
      name: "Wijk",
      validation: { required: true },
      enumValues: {
        "": "",
        Noord: "Noord",
        Oost: "Oost",
        West: "West",
        "Centrum/Binnenstad": "Centrum/Binnenstad",
        Stiphout: "Stiphout",
        Warande: "Warande",
        Dierdonk: "Dierdonk",
        Rijpelberg: "Rijpelberg",
        Brandevoort: "Brandevoort",
        "'t Hout": "'t Hout",
        Industriegebieden: "Industriegebieden",
        Brouwhuis: "Brouwhuis",
      },
      dataType: "string",
    },
    phoneNumber: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    activity: {
      name: "Wat voor activiteit wil je promoten?",
      columnWidth: 350,
      validation: { required: true },
      enumValues: {
        sport: "Sport",
        creatief: "Creatief",
        feestelijks: "Feestelijks",
        social: "Social",
        anders: "Anders",
      },
      dataType: "string",
    },
    // Voor wie is jouw activiteit bedoeld
    targetGroup: {
      name: "Voor wie is jouw activiteit bedoeld?",
      columnWidth: 350,

      validation: { required: true },
      enumValues: {
        jongeren: "Jongeren",
        ouderen: "Ouderen",
        "voor de buurt": "Voor de buurt",
        kinderen: "Kinderen",
        overige: "Overige",
      },
      dataType: "string",
    },
    targetActivity: {
      name: "Overige?",
      validation: { required: false },
      dataType: "string",
    },
    // reference
    entry: {
      name: "Entry",
      validation: { required: false },
      dataType: "reference",
      path: "AgendaEntry",
      previewProperties: ["activiteit"],
    },
  },
});

const agendaEntryCollection = buildCollection({
  path: "AgendaEntry",
  icon: "CalendarIcon",
  group: "Main",
  permissions: ({ user }) => ({
    edit: true,
    create: false,
    delete: true,
    read:
      user?.email === "aanmeldingen@jonginhelmond.nl" ||
      user?.email === "tajjioumohamed97@gmail.com",
  }),
  // Entry in Dutch
  name: "Agenda Binnenkomst",
  properties: {
    acceptAgenda: {
      name: "Accepteer Agenda",
      validation: { required: true },
      dataType: "boolean",
      columnWidth: 250,
    },
    name: {
      name: "Name",
      validation: { required: true },
      dataType: "string",
    },
    achternaam: {
      name: "Achternaam",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      name: "Email",
      validation: { required: true },
      dataType: "string",
      email: true,
    },
    phoneNumber: {
      name: "Telefoonnummer",
      validation: { required: true },
      dataType: "string",
    },
    website: {
      name: "Website",
      validation: { required: true },
      dataType: "string",
    },
    location: {
      name: "Locatie",
      validation: { required: true },
      dataType: "string",
    },
    wijlk: {
      name: "Wijk",
      validation: { required: true },
      enumValues: {
        "": "",
        Noord: "Noord",
        Oost: "Oost",
        West: "West",
        "Centrum/Binnenstad": "Centrum/Binnenstad",
        Stiphout: "Stiphout",
        Warande: "Warande",
        Dierdonk: "Dierdonk",
        Rijpelberg: "Rijpelberg",
        Brandevoort: "Brandevoort",
        "'t Hout": "'t Hout",
        Industriegebieden: "Industriegebieden",
        Brouwhuis: "Brouwhuis",
      },
      dataType: "string",
    },
    activiteit: {
      name: "Activiteit",
      validation: { required: true },
      dataType: "string",
    },
    slogan: {
      name: "Slogan",
      validation: { required: true },
      dataType: "string",
    },
    datumActiviteit: {
      name: "Datum van de Activiteit",
      validation: { required: true },
      dataType: "string",
      disabled: true,
      // parse from date to strin
      Preview: DateStringPreview,
    },
    tijdFrom: {
      name: "Tijd van",
      validation: { required: true },
      dataType: "string",
      enumValues: timeEnums.map((time) => ({
        id: time,
        label: time,
        color: "grayDark",
      })),
    },
    tijdTo: {
      name: "Tijd tot",
      validation: { required: true },
      dataType: "string",
      enumValues: timeEnums.map((time) => ({
        id: time,
        label: time,
        color: "grayDark",
      })),

      // enumValues: timeEnums.map((time) => ({
      //   id: time,
      //   label: time,
      // })),
    },
    // ["", "9 t/m 12 jaar", "13 t/m 23 jaar", "Alle leeftijden"]
    leeftijd: {
      name: "Leeftijd",
      validation: { required: true },
      enumValues: {
        "": "",
        "9 t/m 12 jaar": "9 t/m 12 jaar",
        "13 t/m 23 jaar": "13 t/m 23 jaar",
        "Alle leeftijden": "Alle leeftijden",
      },
      dataType: "string",
    },
    maxAantal: {
      name: "Maximaal aantal",
      validation: { required: true },
      dataType: "string",
    },
  },
});

/**
 * This is an example of how to use the components provided by FireCMS for
 * a better customisation.
 * @constructor
 */
export default function CustomCMSApp() {
  const signInOptions = ["password"];

  const {
    firebaseApp,
    firebaseConfigLoading,
    configError,
    firebaseConfigError,
  } = useInitialiseFirebase({ firebaseConfig });

  const authController: FirebaseAuthController = useFirebaseAuthController({
    firebaseApp,
    signInOptions: ["password"],
  });

  const dataSource = useFirestoreDataSource({
    firebaseApp,
    // You can add your `FirestoreTextSearchController` here
  });

  const storageSource = useFirebaseStorageSource({ firebaseApp });

  const modeController = useBuildModeController();
  const theme = useMemo(
    () =>
      createCMSDefaultTheme({
        mode: "light",
        // mode: modeController.mode,
        primaryColor: "#ffb042",
        secondaryColor: orange[500],
      }),
    []
  );

  const { authLoading, canAccessMainView, notAllowedError } =
    useValidateAuthenticator({
      authController,
      authentication: async ({ user }) => {
        console.log("user warning", user, canAccessMainView, notAllowedError);
        // return true;
        if (user) {
          const tokenResult = await user.getIdTokenResult();
          console.log("tokenResult", tokenResult);
          return true;
          return tokenResult.claims.verified === true;
        }
        return false;
      },
      dataSource,
      storageSource,
    });

  if (configError) {
    return <div> {configError} </div>;
  }

  if (firebaseConfigError) {
    return (
      <div>
        It seems like the provided Firebase config is not correct. If you are
        using the credentials provided automatically by Firebase Hosting, make
        sure you link your Firebase app to Firebase Hosting.
      </div>
    );
  }

  if (firebaseConfigLoading || !firebaseApp) {
    return <CircularProgressCenter />;
  }

  // const customViews: CMSView[] = [{
  //     path: "additional",
  //     name: "Additional view",
  //     description: "This is an example of an additional view that is defined by the user",
  //     // This can be any React component
  //     view: <div style={{ backgroundColor: 'white', width: 100 }}>Dashboard</div>,
  // }];

  return (
    <Router>
      <SnackbarProvider>
        <ModeControllerProvider value={modeController}>
          <FireCMS
            authController={authController}
            collections={[
              agendaCollection,
              promotionCollection,
              organiseerCollection,
              spreekEenJongerenwerkerCollection,
              agendaEntryCollection,
              usersCollection,
              talkRequest,
              // TODO:UNCOMMENT
              // helpCollection,
              // problemRequest,
              // talkRequest,

              // usersCollection,
            ]}
            dataSource={dataSource}
            storageSource={storageSource}
            entityLinkBuilder={({ entity }) =>
              `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`
            }
          >
            {({ context, loading }) => {
              let component;
              if (loading) {
                component = <CircularProgressCenter />;
              } else if (!canAccessMainView) {
                component = (
                  <FirebaseLoginView
                    allowSkipLogin={false}
                    signInOptions={[
                      {
                        provider: "password",
                      },
                    ]}
                    notAllowedError={notAllowedError}
                    // authLoading={authLoading}
                    firebaseApp={firebaseApp}
                    authController={authController}
                  />
                );
              } else {
                component = (
                  <Scaffold name="" logo={logo}>
                    <NavigationRoutes
                      HomePage={() => {
                        return <DashboardPage />;
                      }}
                    />
                    <SideDialogs />
                  </Scaffold>
                );
              }

              console.log("theme");

              return (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  {component}
                  {/* <CssVarsProvider disableTransitionOnChange theme={customTheme}>
                                        <GlobalStyles
                                            styles={{
                                                '[data-feather], .feather': {
                                                    color: 'var(--Icon-color)',
                                                    margin: 'var(--Icon-margin)',
                                                    fontSize: 'var(--Icon-fontSize, 20px)',
                                                    width: '1em',
                                                    height: '1em',
                                                },
                                            }}
                                        />
                                        
                                    </CssVarsProvider> */}
                </ThemeProvider>
              );
            }}
          </FireCMS>
        </ModeControllerProvider>
      </SnackbarProvider>
    </Router>
  );
}
