
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts"

const data = [
  {
    name: "Jan",
    total: Math.floor(Math.random() * 12) + 1,
  },
  {
    name: "Feb",
    total: Math.floor(Math.random() * 11) + 2,
  },
  {
    name: "Maa",
    total: Math.floor(Math.random() * 10) + 3,
  },
  {
    name: "Apr",
    total: Math.floor(Math.random() * 9) + 4,
  },
  {
    name: "Mei",
    total: Math.floor(Math.random() * 8) + 5,
  },
  {
    name: "Jun",
    total: Math.floor(Math.random() * 7) + 6,
  },
  {
    name: "Jul",
    total: Math.floor(Math.random() * 6) + 7,
  },
  {
    name: "Aug",
    total: Math.floor(Math.random() * 5) + 8,
  },
  {
    name: "Sep",
    total: Math.floor(Math.random() * 4) + 9,
  },
  {
    name: "Okt",
    total: Math.floor(Math.random() * 3) + 10,
  },
  {
    name: "Nov",
    total: Math.floor(Math.random() * 2) + 11,
  },
  {
    name: "Dec",
    total: Math.floor(Math.random() * 1) + 12,
  },
]

export function Overview(props: { data: { name: string; total: number }[] }) {
  const { data } = props
  return (
    <ResponsiveContainer width="100%" height={350}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="#888888"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${value}`}
        />
        <Bar dataKey="total" fill="#FFAF42" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  )
}
