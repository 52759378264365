import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import SampleApp from "./SampleApp/SampleApp";

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <SampleApp/>
    </React.StrictMode>,
)
