// import {
//   Avatar,
//   AvatarFallback,
//   AvatarImage,
// } from "@/registry/new-york/ui/avatar"

import { set } from "date-fns";
import { collection, getDocs, getFirestore, limit, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

export function RecentSales({ date }: { date: DateRange | undefined }) {
  const db = getFirestore()
  const [data, setData] = useState<any>([])
  
  useEffect(() => {
    const fetchData = async () => {
      if (!date?.to || !date?.from) return

      const problemRequestRef = collection(db, "SpreekEenJongerenwerker");

      // get collection once and count
      const q = query(problemRequestRef,
        limit(5),
        // where("createdAt", ">=", date.from),
        // where("createdAt", "<=", date.to)
        // where("date", ">=", date.from), where("date", "<=", date.to)
      );
      const querySnapshot = await getDocs(q);
      console.log("gotten data", querySnapshot.docs.map(doc => doc.data()))
      setData(querySnapshot.docs.map(doc => doc.data()))
    }
    fetchData()

  }, [date])

  return (
    <div className="space-y-8">
      {
        data.map((item: any) => (
          <div className="flex items-center">
            {/* <Avatar className="h-9 w-9">
            <AvatarImage src="/avatars/01.png" alt="Avatar" />
            <AvatarFallback>OM</AvatarFallback>
          </Avatar> */}
            <div className="ml-4 space-y-1">
              <p className="text-sm font-medium leading-none">{item.firstName} {item.lastName}</p>
              <p className="text-sm text-muted-foreground">
                {item.email}
              </p>
            </div>
            {/* <div className="ml-auto font-medium">+$1,999.00</div> */}
          </div>
        ))
      }
    </div>
  )
}
