import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CalendarDaysIcon } from "lucide-react"
import { StaticDatePicker, StaticDateTimePicker } from "@mui/x-date-pickers";

export function TableDateField(props: {
    name: string;
    error: Error | undefined;
    mode?: "date" | "date_time";
    internalValue: Date | undefined | null;
    updateValue: (newValue: (Date | null)) => void;
    focused: boolean;
    disabled: boolean;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) {

    const {
        disabled,
        error,
        mode,
        internalValue,
        updateValue
    } = props;

    const PickerComponent = mode === undefined || mode === "date_time"
        ? DateTimePicker
        : StaticDatePicker;

    return (
        <PickerComponent
            value={internalValue ?? null}
            disabled={disabled}
            onChange={(dateValue: Date | null) => {
                updateValue(dateValue);
            }}
            orientation={mode === "date_time" ? "landscape" : "portrait"}
            renderInput={(params) =>
                <MuiTextField {...params}
                    fullWidth
                    variant={"standard"}
                    error={Boolean(error)}
                    style={{
                        height: "100%"
                    }}

                    InputProps={{
                        className: "dark:text-white",
                        ...params.InputProps,
                        style: { fontSize: 14 },
                        disableUnderline: true,
                        endAdornment: <div className="pr-1">
                            {params.InputProps?.endAdornment}
                        </div>
                    }}
                />}
            InputAdornmentProps={{
                style: {
                    fontSize: "small",
                    height: 26,
                }
            }}
        />
    );
}
